import React from 'react'
import carsten from '../../../assets/images/story/productsup/carsten.png'
import solution1 from '../../../assets/images/story/productsup/productsup-alerts-based-on-working-agreements.png'
import solution3 from '../../../assets/images/story/productsup/productsup-cycle-time.png'
import solution2 from '../../../assets/images/story/productsup/productsup-work-log.png'
import roland from '../../../assets/images/story/productsup/roland.png'
import { InlineQuote } from '../../blocks/InlineQuote'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'

const ProductsupBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <p>
            Productsup frees brands, retailers, service providers, and
            marketplaces from commerce anarchy, empowering them to take control
            of their own destinies. Founded in 2010, the company works with over
            900 brands, including IKEA, Sephora, and ALDI.
          </p>
        ),
        href: 'https://productsup.com',
        location: <p>Headquartered in Berlin, serving customers globally</p>,
        headcount: <p>250+ employees, 60+ engineers</p>,
        customers: <p>2021</p>,
      }}
      main={
        <>
          <p>
            The Productsup product-to-consumer (P2C) platform processes over two
            trillion products a month, empowering long-term business success as
            the only global, strategic, scalable platform managing all
            product-to-consumer information value chains across any platform,
            any channel, any technology.
          </p>
          <p>
            When Productsup SVP of Engineering, Roland Fiala, first heard about
            Swarmia in late 2020, he was leading an organization of 20 software
            engineers split across a few teams. As the company grew, the
            Productsup engineering organization decided to transition from
            Kanban to Scrum to create more structure.
          </p>
          <InlineQuote
            quote="When we were small, Kanban was the ideal choice as it allowed us to simply work on the highest priorities. But as soon as the team grew, I no longer had a clear overview of everything."
            name="Roland Fiala"
            title="SVP of Engineering"
            photo={roland}
          />
          <p>
            And to make the engineering organization&apos;s work visible to the
            business, Roland started to report on three key metrics:
          </p>
          <ol>
            <li>Lead time for features</li>
            <li>Bug fix time</li>
            <li>The ratio of fixed bugs vs. new bugs</li>
          </ol>
          <p>
            Roland also recognized that hiring, promoting, and training new team
            leads was going to be one of the biggest challenges he would have to
            solve in the near future.
          </p>
          <InlineQuote
            quote="It's not easy to find the right tools to empower team leads. To set them up for success, I wanted to give them full transparency into what’s going on within their teams. With Swarmia, my team leads can better manage the people and projects they work with."
            name="Roland Fiala"
            title="SVP of Engineering"
            photo={roland}
          />

          <h2>
            Enabling team leads to run better retrospectives and proactively
            remove blockers
          </h2>

          <p>
            When Roland and his team started looking into different solutions,
            they wanted to find a tool that would help them report on key
            performance metrics, provide an overview of what&apos;s going on in
            the department, and enable team leads to proactively identify and
            remove blockers.
          </p>
          <p>
            After comparing LinearB and Swarmia, Roland and his team came to the
            conclusion that Swarmia was better suited for their needs. The
            Productsup team was particularly excited about Swarmia&apos;s
            approach to continuous improvement through the use of
            self-configurable working agreements.
          </p>
          <ProductScreenshot src={solution1} />
          <p>
            And when Carsten Neuendorf, Director of Engineering, joined
            Productsup in April 2021, he started supporting team retrospectives
            and weekly check-ins using insights from Swarmia.
          </p>
          <InlineQuote
            quote="We have a team check-in every Monday morning where we go over the working agreements we've set in Swarmia and discuss any possible exceptions. We're also using Swarmia in our team retrospectives to see how different tasks are moving forward."
            name="Carsten Neuendorf"
            title="Director of Engineering"
            photo={carsten}
          />
          <ProductScreenshot src={solution2} />
          <p>
            Overall, Roland and Carsten have been slowly rolling out Swarmia
            across the engineering organization. In addition to the product
            itself, Roland and Carsten praise the close partnership and the
            Swarmia team&apos;s openness to receiving and actioning product
            feedback and feature requests.
          </p>
          <h2>69% decrease in cycle time and 38% decrease in review time</h2>
          <p>
            With the help of Swarmia, Carsten&apos;s team was able to cut down
            cycle time by 69% and increase throughput by 51% within four weeks.
          </p>
          <ProductScreenshot src={solution3} />
          <p>
            Even more than the impressive numbers, Carsten appreciates the sense
            of ownership and autonomy that working agreements have brought to
            his team.
          </p>
          <InlineQuote
            quote="The team members set their own working agreements based on what's relevant and achievable for them. Swarmia reminds each of them if they're about to slip, which takes the pressure off the team lead."
            name="Carsten Neuendorf"
            title="Director of Engineering"
            photo={carsten}
          />
          <p>
            On the other hand, Roland points out that exceptions in working
            agreements provide a great opportunity for engineering managers to
            help unblock issues. When a manager notices an exception, such as a
            stale pull request, they&apos;re able to check in with team members
            and start important conversations that can improve product quality
            and collaboration within the team.
          </p>
          <p>
            Ultimately, Roland believes that Swarmia has helped him gain the
            transparency and visibility he was after.
          </p>
          <InlineQuote
            quote="Swarmia gives us the perfect overview of engineering productivity. From the insights page, I can quickly see how we’re doing in terms of cycle time and pull requests, which helps me report back to the business."
            name="Roland Fiala"
            title="SVP of Engineering"
            photo={roland}
          />
        </>
      }
    />
  )
}
export default ProductsupBody
