import React from 'react'
import Results from '../Results'

const ProductsupResults = () => {
  return (
    <Results
      results={[
        { name: 'cycle time', value: 69, dir: 'down' },
        { name: 'throughput', value: 51, dir: 'up' },
        { name: 'review time', value: 38, dir: 'down' },
      ]}
    />
  )
}
export default ProductsupResults
