import React from 'react'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import ProductsupBody from '../../components/story/productsup/Content'
import ProductsupHeader from '../../components/story/productsup/Header'
import ProductsupResults from '../../components/story/productsup/Results'
import MetaImage from '../../assets/images/story/productsup/Productsup-cover.png'

const ProductsupPage = () => {
  return (
    <Layout
      title="Productsup case study"
      description="Productsup SVP of Engineering and Director of Engineering share how they're using Swarmia to help their highly-skilled engineers deliver better software faster."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <ProductsupHeader />
        <ProductsupResults />
        <ProductsupBody />
      </CaseStudyContainer>
      <CTASection>
        Unblock the flow. Join&nbsp;the&nbsp;1,000+ teams using Swarmia today.
      </CTASection>
    </Layout>
  )
}
export default ProductsupPage
