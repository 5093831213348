import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const ProductsupHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      company="Productsup"
      heading="How Productsup uses Swarmia to support team leads and speed up software delivery"
      subheading="Productsup Senior Vice President of Engineering, Roland Fiala, and Director of Engineering, Carsten Neuendorf, are using Swarmia to help their highly-skilled engineers deliver better software faster."
      featured={featured}
    />
  )
}

export default ProductsupHeader

const query = graphql`
  {
    hero: file(
      relativePath: { eq: "images/story/productsup/productsup-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
